import {Component, Inject, Injector, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'missions-info-component',
    templateUrl: './missions-info.component.html',
    styleUrls: ['./missions-info.component.scss']
})
export class MissionsInfoComponent {
    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
    }
}
