import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modal-spinner-placeholder',
  templateUrl: './modal-spinner-placeholder.component.html',
  styleUrls: ['./modal-spinner-placeholder.component.scss']
})
export class ModalSpinnerPlaceholderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
