import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'paginator-container',
  templateUrl: './paginator-container.component.html',
  styleUrls: ['./paginator-container.component.scss']
})
export class PaginatorContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
