export enum Theme {
    Beet = 'beet',
    Dark = 'dark',
    Gray = 'gray',
    Light = 'light',
    Olive = 'olive',
    Orange = 'orange',
    Purple = 'purple',
    Sky = 'sky',
    Teal = 'teal'
}
