import { IBFTATradeProbability, IBFTATradeType, IBFTATrend, IBFTScanInstrumentsResponseItem, InstrumentTypeId } from "@app/services/algo.service";

export const mockedSonarData: IBFTScanInstrumentsResponseItem[] = [
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 6,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "Oanda",
        symbol: "CAD_SGD",
        entry: 1.07421875,
        stop: 1.0598575367647058,
        isMocked: true,
        marketType: InstrumentTypeId.Other
    },
    {
        trend: IBFTATrend.Down,
        type: IBFTATradeType.SwingN,
        tte: 12,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "Oanda",
        symbol: "HK33_HKD",
        entry: 29687.5000000001,
        stop: 30606.617647058923,
        isMocked: true,
        marketType: InstrumentTypeId.Indices
    },
    {
        trend: IBFTATrend.Down,
        type: IBFTATradeType.SwingN,
        tte: 6,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "Oanda",
        symbol: "SGD_CHF",
        entry: 0.683593750000001,
        stop: 0.6979549632352952,
        isMocked: true,
        marketType: InstrumentTypeId.Other
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 12,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "Oanda",
        symbol: "CHF_JPY",
        entry: 118.75,
        stop: 115.07352941176471,
        isMocked: true,
        marketType: InstrumentTypeId.ForexMinors
    },
    {
        trend: IBFTATrend.Down,
        type: IBFTATradeType.SwingN,
        tte: 6,
        tp: IBFTATradeProbability.Low,
        timeframe: 900,
        exchange: "Oanda",
        symbol: "EUR_CHF",
        entry: 1.0986328125,
        stop: 1.105813419117647,
        isMocked: true,
        marketType: InstrumentTypeId.ForexMinors
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.BRC,
        tte: 9,
        tp: IBFTATradeProbability.Mid,
        timeframe: 14400,
        exchange: "Oanda",
        symbol: "SOYBN_USD",
        entry: 15.625,
        stop: 15.234375,
        isMocked: true,
        marketType: InstrumentTypeId.Commodities
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.BRC,
        tte: 29,
        tp: IBFTATradeProbability.Low,
        timeframe: 900,
        exchange: "Oanda",
        symbol: "AU200_AUD",
        entry: 7109.37500000001,
        stop: 7089.84375000001,
        isMocked: true,
        marketType: InstrumentTypeId.Indices
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.BRC,
        tte: 7,
        tp: IBFTATradeProbability.High,
        timeframe: 3600,
        exchange: "Oanda",
        symbol: "AU200_AUD",
        entry: 7109.37500000001,
        stop: 7089.84375000001,
        isMocked: true,
        marketType: InstrumentTypeId.Indices
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 18,
        tp: IBFTATradeProbability.Low,
        timeframe: 3600,
        exchange: "Oanda",
        symbol: "XAU_EUR",
        entry: 1437.5,
        stop: 1363.9705882352941,
        isMocked: true,
        marketType: InstrumentTypeId.Metals
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 10,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "Oanda",
        symbol: "XAU_USD",
        entry: 1750,
        stop: 1676.4705882352941,
        isMocked: true,
        marketType: InstrumentTypeId.Metals
    },
    {
        trend: IBFTATrend.Down,
        type: IBFTATradeType.SwingN,
        tte: 0,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "Oanda",
        symbol: "GBP_CHF",
        entry: 1.26953125,
        stop: 1.3269761029411764,
        isMocked: true,
        marketType: InstrumentTypeId.ForexMinors
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingExt,
        tte: 2,
        tp: IBFTATradeProbability.High,
        timeframe: 14400,
        exchange: "Oanda",
        symbol: "EUR_GBP",
        entry: 0.859832763671876,
        stop: 0.857086181640626,
        isMocked: true,
        marketType: InstrumentTypeId.ForexMinors
    },
    {
        trend: IBFTATrend.Down,
        type: IBFTATradeType.SwingN,
        tte: 12,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "NYSE",
        symbol: "BA",
        entry: 250,
        stop: 259.19117647058823,
        isMocked: true,
        marketType: InstrumentTypeId.Commodities
    },
    {
        trend: IBFTATrend.Down,
        type: IBFTATradeType.SwingN,
        tte: 30,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "NASDAQ",
        symbol: "BYND",
        entry: 137.5,
        stop: 152.2058823529412,
        isMocked: true,
        marketType: InstrumentTypeId.Commodities
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 2,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "NASDAQ",
        symbol: "FB",
        entry: 312.5,
        stop: 294.11764705882354,
        isMocked: true,
        marketType: InstrumentTypeId.Commodities
    },
    {
        trend: IBFTATrend.Down,
        type: IBFTATradeType.SwingN,
        tte: 4,
        tp: IBFTATradeProbability.Low,
        timeframe: 14400,
        exchange: "NYSE",
        symbol: "FIT",
        entry: 7.03125,
        stop: 7.146139705882353,
        isMocked: true,
        marketType: InstrumentTypeId.Commodities
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 8,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "NYSE",
        symbol: "WMT",
        entry: 137.5,
        stop: 133.8235294117647,
        isMocked: true,
        marketType: InstrumentTypeId.Commodities
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 16,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "Binance",
        symbol: "eth-btc",
        entry: 0.04882812500000005,
        stop: 0.034466911764705906,
        isMocked: true,
        marketType: InstrumentTypeId.Crypto
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.BRC,
        tte: 4,
        tp: IBFTATradeProbability.Mid,
        timeframe: 900,
        exchange: "Binance",
        symbol: "eth-usdt",
        entry: 4062.500000000005,
        stop: 3984.3750000000036,
        isMocked: true,
        marketType: InstrumentTypeId.Crypto
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 6,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "Binance",
        symbol: "link-btc",
        entry: 0.0007629394531250005,
        stop: 0.0005385454963235302,
        isMocked: true,
        marketType: InstrumentTypeId.Crypto
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.BRC,
        tte: 2,
        tp: IBFTATradeProbability.High,
        timeframe: 3600,
        exchange: "Binance",
        symbol: "link-usdt",
        entry: 50,
        stop: 49.21875,
        isMocked: true,
        marketType: InstrumentTypeId.Crypto
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 4,
        tp: IBFTATradeProbability.Low,
        timeframe: 86400,
        exchange: "Binance",
        symbol: "ltc-btc",
        entry: 0.006103515625,
        stop: 0.004308363970588236,
        isMocked: true,
        marketType: InstrumentTypeId.Crypto
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.BRC,
        tte: 4,
        tp: IBFTATradeProbability.High,
        timeframe: 3600,
        exchange: "Binance",
        symbol: "ltc-usdt",
        entry: 375,
        stop: 359.375,
        isMocked: true,
        marketType: InstrumentTypeId.Crypto
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.BRC,
        tte: 4,
        tp: IBFTATradeProbability.Low,
        timeframe: 900,
        exchange: "Binance",
        symbol: "xrp-btc",
        entry: 0.00002682209014892585,
        stop: 0.00002644956111907966,
        isMocked: true,
        marketType: InstrumentTypeId.Crypto
    },
    {
        trend: IBFTATrend.Up,
        type: IBFTATradeType.SwingN,
        tte: 5,
        tp: IBFTATradeProbability.Mid,
        timeframe: 86400,
        exchange: "Binance",
        symbol: "xrp-btc",
        entry: 0.00002384185791015625,
        stop: 0.00001682954676011028,
        isMocked: true,
        marketType: InstrumentTypeId.Crypto
    }
];